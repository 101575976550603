var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"F2gCard",class:{
    'ma-1': _vm.margin,
    'pl-6 pr-6 pt-6 pb-1': _vm.padding,
    round: _vm.round,
    'round-only-top': _vm.roundOnlyTop,
    'round-only-bottom': _vm.roundOnlyBottom,
  },style:(_vm.getStyle),attrs:{"outlined":"","hover":_vm.hover,"ripple":_vm.ripple},nativeOn:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }