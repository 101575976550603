<template>
  <v-card
    class="F2gCard"
    outlined
    :hover="hover"
    :class="{
      'ma-1': margin,
      'pl-6 pr-6 pt-6 pb-1': padding,
      round: round,
      'round-only-top': roundOnlyTop,
      'round-only-bottom': roundOnlyBottom,
    }"
    :style="getStyle"
    :ripple="ripple"
    @click.native="$emit('click')"
  >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "F2gCard",
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    shadowDown: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    roundOnlyTop: {
      type: Boolean,
      default: false,
      required: false,
    },
    roundOnlyBottom: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    getStyle() {
      let style = "";
      if (this.noShadow) {
        style = "box-shadow: none !important";
      }
      if (this.shadowDown) {
        style = "box-shadow:0px 4px 8px -3px rgb(0 0 0 / 20%) !important";
      }
      if (this.disabled) {
        style += "opacity: 0.6";
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: 0 23px 20px -20px rgba(96, 148, 201, 0.3),
    2px -2px 10px rgba(45, 125, 204, 0.12) !important;
  border: none !important;
  border-radius: 10px;
}

.v-card--hover:hover {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.round {
  border-radius: 25px;
}

.round-only-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.round-only-bottom {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
</style>
